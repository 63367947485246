<template>
  <div
    class="Home h-full"
    @openterms="showTermsModal = true"
  >
    <Nav />
    <Parallax>
      <main>
        <IntroAlt />
        <picture>
          <source
            media="(orientation: portrait)"
            :data-srcset="`${photo.portrait['320w']} 320w,
            ${photo.portrait['576w']} 576w,
            ${photo.portrait['768w']} 768w,
            ${photo.portrait['992w']} 992w`"
            sizes="100vw"
          >
          <img
            class="Parallax-item lazyload w-full fit-cover block"
            :data-src="photo.landscape['1200w']"
            :data-srcset="`${photo.landscape['768w']} 768w,
            ${photo.landscape['992w']} 992w,
            ${photo.landscape['1200w']} 1200w`"
            sizes="100vw"
            aria-hidden="true"
            alt
          >
        </picture>
        <ProductSection />
        <CharitySection />
        <ConfessSection />
        <AboutSection />
      </main>
    </Parallax>
    <Modal
      v-show="showTermsModal"
      @close="showTermsModal = false"
    >
      <Terms />
    </Modal>
  </div>
</template>

<script>
import Nav from '../components/Nav.vue';
import Parallax from '../components/Parallax.vue';
import IntroAlt from '../components/IntroAlt.vue';
import ProductSection from '../components/ProductSection.vue';
import CharitySection from '../components/CharitySection.vue';
import ConfessSection from '../components/ConfessSection.vue';
import Modal from '../components/Modal.vue';
import Terms from '../components/Terms.vue';
import AboutSection from '../components/AboutSection.vue';

/* eslint-disable global-require */
const photo = {
  landscape: {
    '768w': require('../assets/pecans-bg-768w.jpg'),
    '992w': require('../assets/pecans-bg-992w.jpg'),
    '1200w': require('../assets/pecans-bg-1200w.jpg'),
  },
  portrait: {
    '320w': require('../assets/pecans-bg-portrait-320w.jpg'),
    '576w': require('../assets/pecans-bg-portrait-576w.jpg'),
    '768w': require('../assets/pecans-bg-portrait-768w.jpg'),
    '992w': require('../assets/pecans-bg-portrait-992w.jpg'),
  },
};
/* eslint-enable global-require */

export default {
  components: {
    Nav,
    Parallax,
    IntroAlt,
    ProductSection,
    CharitySection,
    ConfessSection,
    Modal,
    Terms,
    AboutSection,
  },
  data() {
    return {
      showTermsModal: false,
    };
  },
  computed: {
    photo() {
      return photo;
    },
  },
};
</script>
