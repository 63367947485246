<template>
  <a
    :href="to"
    @click.prevent="go"
  >
    <slot />
  </a>
</template>

<script>
import { push } from '../history';

export default {
  props: {
    to: {
      type: String,
      required: true,
    },
  },
  methods: {
    go() {
      push(this.to);
    },
  },
};
</script>
