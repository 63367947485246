<template v-if="!link">
  <button
    class="Button Button--red"
  >
    <slot />
  </button>
</template>
<template v-else>
  <a
    class="no-underline"
    :href="link"
    :target="isExternal ? '_blank' : ''"
    :rel="isExternal ? 'noopener' : ''">
    <button class="Button Button--red"><slot></slot></button>
  </a>
</template>

<script>
export default {
  name: 'Button',
  props: {
    link: {
      type: String,
      default: '',
    },
    isExternal: {
      type: Boolean,
    },
  },
};
</script>

<style lang="postcss">
  .Button {
    @apply font-serif uppercase font-bold tracking-wider;
    @apply text-sm;
    @apply rounded px-5 py-0 h-16 shadow;
    @apply block w-full mx-auto max-w-xs;

    &[disabled] {
      @apply opacity-50;
    }

    &:hover {
      @apply shadow-md;
    }

    @screen sm {
      @apply text-base;
    }
  }

  .Button--red {
    @apply bg-red text-white;
    &:hover {
      background-color: color( config(colors.red) shade(10%));
    }
  }

  .Button--secondary {
    @apply bg-transparent text-white;
    @apply border-2;
    &:hover {
      @apply bg-white text-grey-darkest;
    }
  }
</style>
