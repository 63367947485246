<template>
  <div
    id="app"
    class="h-full text-center text-grey-darker antialiased font-serif"
  >
    <Router />
  </div>
</template>

<script>
import './global-styles/reset.pcss';
import './global-styles/patterns.pcss';
import Router from './Router.vue';
import './global-styles/utilities.pcss';

export default {
  name: 'App',
  components: {
    Router,
  },
};
</script>
