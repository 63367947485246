<template>
  <div class="h-full bg-red bg-gradient-linear-x-red flex items-center justify-center">
    <div class="max-w-md relative curve-y bg-white shadow-lg p-12">
      <p>
        The Krampus would thank you but that’s not his style.
      </p>
      <p>
        Still worried your appeal will fall on deaf ears?
      </p>
      <p>
        Pick up a can of kibble and remove all doubt.
      </p>
      <ViewLink
        to="/"
        class="text-gold-dark uppercase tracking-wider"
      >
        Back to the site
      </ViewLink>
    </div>
  </div>
</template>

<script>
import ViewLink from '../components/ViewLink.vue';

export default {
  components: {
    ViewLink,
  },
};
</script>
