<template>
  <form
    name="confess-to-krampus"
    method="post"
    data-netlify="true"
    data-netlify-honeypot="bot-field"
    class="Form curve-y"
    novalidate="true"
    @submit.prevent="handleSubmit"
  >
    <input
      type="hidden"
      name="form-name"
      value="confess-to-krampus"
    >
    <label
      class="hidden"
      aria-hidden="true"
    >
      Don’t fill this out if you’re human: <input name="bot-field">
    </label>
    <div class="mb-8">
      <label
        for="form-field-confession"
        class="Form-label"
      >
        Confession
      </label>
      <textarea
        id="form-field-confession"
        v-model="form.confession"
        class="Form-field"
        :class="{
          'Form-field--error': errors.emptyConfession
        }"
        :disabled="disabled === true"
        name="confession"
        cols="30"
        rows="5"
        placeholder="Dear Krampus, This year I’m ashamed to admit that I…"
      />
      <p
        v-if="errors.emptyConfession"
        class="Form-fieldHint Form-fieldHint--error"
      >
        Krampus requires your written confession.
      </p>
    </div>

    <div class="mb-8">
      <label
        for="form-field-alias"
        class="Form-label"
      >
        Your Alias
      </label>
      <input
        id="form-field-alias"
        v-model="form.alias"
        class="Form-field"
        :class="{
          'Form-field--error': errors.emptyAlias
        }"
        :disabled="disabled === true"
        type="text"
        name="alias"
        placeholder="Merry Mercy-Seeker"
      >
      <p
        v-if="errors.emptyAlias"
        class="Form-fieldHint Form-fieldHint--error"
      >
        Krampus requires an alias.
      </p>
    </div>

    <div class="mb-8">
      <label
        for="form-field-email"
        class="Form-label"
      >
        Email Address
      </label>
      <input
        id="form-field-email"
        v-model="form.email"
        class="Form-field"
        :class="{
          'Form-field--error': errors.invalidEmail || errors.emptyEmail,
        }"
        :disabled="disabled === true"
        type="email"
        name="email"
      >
      <p
        v-if="errors.emptyEmail"
        class="Form-fieldHint Form-fieldHint--error"
      >
        Krampus requires an email to contact you if you win.
      </p>
      <p
        v-else-if="errors.invalidEmail"
        class="Form-fieldHint Form-fieldHint--error"
      >
        That email seems nutty. Make sure it’s valid.
      </p>
      <p
        v-else
        class="Form-fieldHint"
      >
        No worries. Krampus may punish ill-mannered munchkins, but he’ll never send you spam.
      </p>
    </div>

    <input
      type="submit"
      value="Confess now"
      class="Button Button--red"
      :disabled="disabled === true"
    >
  </form>
</template>

<script>
import { push } from '../history';

const Errors = () => ({
  emptyAlias: false,
  emptyConfession: false,
  emptyEmail: false,
  invalidEmail: false,
});

export default {
  data() {
    return {
      errors: Errors(),
      disabled: false,
      form: {
        alias: '',
        email: '',
        confession: '',
      },
    };
  },
  computed: {
    valid() {
      const errors = Object.values(this.errors);
      return !errors.some(error => error === true);
    },
  },
  methods: {
    encode(data) {
      return Object.keys(data)
        .map(
          key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
        )
        .join('&');
    },
    handleSubmit() {
      // validate form inputes
      this.checkInputs();

      if (this.valid) {
        // disable form inputs
        this.disabled = true;

        // make request
        fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: this.encode({
            'form-name': 'confess-to-krampus',
            ...this.form,
          }),
        })
          .then((response) => { // test if response is ok
            if (response.ok) {
              // route to success state
              push('/success');
            }
            throw new Error('Response was not ok.');
          })
          .catch((err) => { // show error state
            // eslint-disable-next-line no-console
            console.error(err);
          });
      }
    },
    checkInputs() {
      // clear errors
      this.errors = Errors();

      // require confession
      if (!this.form.confession) {
        this.errors.emptyConfession = true;
      }

      // require alias
      if (!this.form.alias) {
        this.errors.emptyAlias = true;
      }

      // require email
      if (!this.form.email) {
        this.errors.emptyEmail = true;
      // require valid email
      } else if (!this.validEmail(this.form.email)) {
        this.errors.invalidEmail = true;
      }
    },
    validEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
  },
};
</script>


<style lang="postcss">
.Form {
  @apply text-base text-grey-darker text-left;
  @apply bg-white shadow-lg px-6 py-4;
  @apply max-w-sm w-full;
  @apply relative;

  @screen lg {
    @apply py-6 px-8;
  }
}

.Form-field {
  @apply block appearance-none bg-grey-lightest rounded p-3 w-full border border-transparent;
  @apply leading-normal;

  &[disabled] {
    @apply opacity-50;
  }

  &:focus {
    @apply bg-white border-grey-darker outline-none;
  }

  &--error {
    @apply border-red;
  }
}

.Form-fieldHint {
  @apply mt-2 mb-0 leading-tight;

  &--error {
    @apply text-red;
  }
}

.Form-label {
  @apply text-sm block mb-3;
  @apply text-grey-darkest uppercase tracking-wider font-bold;

  @screen md {
    @apply text-base;
  }
}
</style>
