<template>
  <Component :is="routedComponent" />
</template>

<script>
import Home from './pages/Home.vue';
import Success from './pages/Success.vue';
import { listen } from './history';

const routes = {
  '/': Home,
  '/success': Success,
};

export default {
  data() {
    return { current: window.location.pathname };
  },
  computed: {
    routedComponent() {
      return routes[this.current] || Home;
    },
  },
  created() {
    // register handler for route changes initiated by history module (./history.js)
    listen((route) => {
      this.current = route; // update the routed component
      this.$ga.page(route); // fire Google Analytics page event
    });

    // register handler for route changes initiated by browser ui (e.g. back button)
    window.addEventListener('popstate', () => {
      this.current = window.location.pathname; // update the routed component
      this.$ga.page(this.current); // fire Google Analytics page event
    });

    // fire initial Google Analytics page event
    this.$ga.page(this.current);
  },
};
</script>
