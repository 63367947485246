<template>
  <section
    id="get-krampus-kibble"
  >
    <div class="pt-32 pb-16 lg:pb-32">
      <div class="px-4">
        <div class="ProductCard curve-y lg:curve-y-lg">
          <img
            class="ProductCard-figure lazyload"
            data-src="@/assets/krampus-kibble-can-560w.png"
            alt="Krampus Kibble"
          >
          <div class="lg:pl-12 lg:border-l-4 lg:border-gold-lightest">
            <span
              class="block text-style-heading text-grey-darkest text-4xl sm:text-5xl"
            >
              $15.00
            </span>
            <small
              class="block text-grey-darkest mb-4 lg:mb-6 uppercase font-serif tracking-wider
            font-bold text-sm mx-auto"
            >
              per 8oz can
            </small>
            <p>
              Each can is packed with perfectly roasted pecans, lightly coated in sugar
              and sprinkled with sea salt.
            </p>
            <p>
              One crunch and you’ll see why Krampus can’t get enough.
            </p>
            <Button
              v-ga="$ga.commands.trackOutbound.bind(this, 'https://www.google.com/maps/search/?api=1&query=Nutkrack%2C+Madison%2C+WI&query_place_id=ChIJMW9wSoZTBogRtW2DPlT8DZ8')"
              class="block md:mt-10"
              is-external
              link="https://www.google.com/maps/search/?api=1&query=Nutkrack%2C+Madison%2C+WI&query_place_id=ChIJMW9wSoZTBogRtW2DPlT8DZ8"
            >
              Pick up a can
            </Button>
            <small
              class="text-gold-dark block mt-4 lg:mt-5 uppercase font-serif tracking-wider font-bold
              text-sm mx-auto"
            >
              Only available in-store at<br>Nutkrack in Madison, WI
            </small>
          <!-- https://www.google.com/maps/dir/?api=1&destination=Nutkrack&destination_place_id=ChIJMW9wSoZTBogRtW2DPlT8DZ8 -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Button from './Button.vue';

export default {
  name: 'ProductSection',
  components: {
    Button,
  },
};
</script>

<style lang="postcss">
.ProductCard {
  @apply bg-white shadow-lg;
  @apply relative p-5;
  @apply text-grey-darker;

  @screen md {
    @apply px-12 py-6;
    @apply max-w-sm mx-auto;
  }

  @screen lg {
    @apply max-w-xl flex items-center;
  }
}

.ProductCard-figure {
  @apply mb-8;
  margin-top: -25%;
  filter: drop-shadow(0 7px 7px rgba(0, 0, 0, 0.1));

  @screen sm {
    @apply w-full mb-8;
  }

  @screen lg {
    @apply flex-none my-0 w-3/5 pr-12;
    @apply relative z-10;
    margin-top: -10%;
  }
}
</style>
