<template>
  <section
    id="for-charity"
    class="bg-white relative py-12 md:py-20 px-6 md:px-8"
  >
    <div class="max-w-xl mx-auto">
      <header>
        <h1 class="text-style-heading text-4xl md:text-6xl text-grey-darkest mb-3">
          Nutty and Nice
        </h1>
        <p class="md:text-xl lg:text-2xl mb-8 sm:mb-12">
          Offering Krampus his favorite treat not only appeases the beast, but also helps out the
          folks at the Goodman Community Center’s Fritz Food Pantry. With all profits
          benefitting neighbors in need, turns out nutty’s a good place to put some nice.
        </p>
        <hr class="hidden md:block border-b-4 border-gold-lightest mb-8 mb-10 sm:mb-16">
      </header>
      <div class="md:flex md:text-left">
        <picture class="md:flex-none md:w-1/3 lg:w-2/5">
          <source
            media="(max-width: 991px)"
            :data-srcset="photo.portrait['662w']"
          >
          <img
            :data-src="photo.landscape['576w']"
            :data-srcset="`${photo.landscape['768w']} 768w, ${photo.landscape['576w']} 576w`"
            sizes="384px"
            alt="Inside the Fritz Food Pantry at Goodman Community Center"
            class="lazyload rounded shadow-md block"
          >
        </picture>
        <div class="flex-auto md:ml-10 lg:ml-16">
          <h2
            class="text-style-heading text-2xl md:text-3xl lg:text-4xl mb-3 mt-8 md:mt-0
            text-grey-darkest"
          >
            About the Fritz Food Pantry
          </h2>
          <p>
            The Goodman Community Center’s Fritz Food Pantry provides fresh, local
            and organic groceries for up to 100 hungry families in the community.
          </p>
          <p>
            Plus, it’s a place where those in need can get together for warm meals
            throughout the week.
          </p>
          <span class="text-gold-dark">
            <svg class="fill-current w-5 h-5 align-middle mr-2" svg-inline="" role="presentation" focusable="false" xmlns="http://www.w3.org/2000/svg" data-icon="external-link" viewBox="0 0 16 20"><path d="M11 0l1.781 1.781-.5.5-4 4L7.593 7l1.406 1.406.719-.688 4-4 .5-.5 1.781 1.781v-5h-5zM0 2v14h14V8h-2v6H2V4h6V2H0z"/></svg>
            <a
              v-ga="$ga.commands.trackOutbound.bind(this, 'https://www.goodmancenter.org/services/fritz-food-pantry')"
              class="text-gold-dark uppercase font-bold tracking-wider text-sm md:text-base"
              href="https://www.goodmancenter.org/services/fritz-food-pantry"
              target="_blank"
              rel="noopener"
            >
              Learn how you can help
            </a>
          </span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

/* eslint-disable global-require */
const photo = {
  portrait: {
    '662w': require('../assets/fritz-pantry-portrait-662w.jpg'),
  },
  landscape: {
    '768w': require('../assets/fritz-pantry-landscape-768w.jpg'),
    '576w': require('../assets/fritz-pantry-landscape-576w.jpg'),
  },
};
/* eslint-enable global-require */

export default {
  name: 'CharitySection',
  computed: {
    photo() {
      return photo;
    },
  },
};
</script>
