import Vue from 'vue';
import VueAnalytics from 'vue-analytics';
import VueScrollTo from 'vue-scrollto';
import App from './App.vue';

import 'lazysizes';

const isProd = process.env.NODE_ENV === 'production';

Vue.config.productionTip = false;

Vue.use(VueAnalytics, {
  id: 'UA-4856079-10',
  autotracking: {
    exception: true,
  },
  commands: {
    trackOutbound(url = 'unknown') {
      this.$ga.event({
        eventCategory: 'Outbound Link',
        eventAction: 'click',
        eventLabel: url,
        transport: 'beacon',
      });
    },
  },
  debug: {
    enabled: !isProd,
    sendHitTask: isProd,
  },
});

Vue.use(VueScrollTo, {
  container: '.Parallax-wrap',
  duration: 500,
  easing: 'ease',
  offset: -57,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
});

new Vue({
  render: h => h(App),
}).$mount('#app');
